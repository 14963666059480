'use client'

import { useRef, useEffect } from 'react'
import dynamic from 'next/dynamic'

import * as development from '../../../public/lottie/development.json'
import * as about from '../../../public/lottie/about.json'
import * as ai from '../../../public/lottie/ai.json'
import * as design from '../../../public/lottie/design.json'
import * as web from '../../../public/lottie/web.json'
import * as marketing from '../../../public/lottie/marketing.json'
import * as app from '../../../public/lottie/app.json'

const animations = {
  development: development
  ,about: about
  ,'artificial-intelligence': ai
  ,'web-development': web
  ,'digital-marketing': marketing
  ,'app-development': app
  ,'graphic-design': design
}

// import Lottie from 'lottie-react'

const Lottie = dynamic(() => import('lottie-react'), { ssr: false })

type props = {
  animation: keyof typeof animations;
  speed?: number;
  loop: boolean;
  autoplay: boolean;
  rendererSettings: any;
}

function LottiePlayer({ animation, speed, loop, autoplay, rendererSettings }: props) {
  const lottieRef = useRef<null | any>(null)

  useEffect(() => {
    if (lottieRef.current) {
      lottieRef.current?.setSpeed(speed ? speed : 1)
    }
  }, [lottieRef, speed])

  return <Lottie
    lottieRef={lottieRef}
    loop={loop}
    autoplay={autoplay}
    animationData={{...animations[animation]}}
    rendererSettings={rendererSettings} 
  />
}

export default LottiePlayer







// 'use client'

// import dynamic from 'next/dynamic'

// import * as development from '../../../public/lottie/development.json'
// import * as about from '../../../public/lottie/about.json'
// import * as ai from '../../../public/lottie/ai.json'
// import * as design from '../../../public/lottie/design.json'
// import * as web from '../../../public/lottie/web.json'
// import * as marketing from '../../../public/lottie/marketing.json'
// import * as app from '../../../public/lottie/app.json'

// const animations = {
//   development: development
//   , about: about
//   , 'artificial-intelligence': ai
//   , 'web-development': web
//   , 'digital-marketing': marketing
//   , 'app-development': app
//   , 'graphic-design': design
// }

// import { DotLottieReact } from '@lottiefiles/dotlottie-react';

// type props = {
//   animation: keyof typeof animations;
//   speed?: number;
//   loop: boolean;
//   autoplay: boolean;
//   rendererSettings: any;
// }

// function LottiePlayer({ animation, speed, loop, autoplay, rendererSettings }: props) {

//   return (
//     <DotLottieReact
//       // dotLottieRefCallback={lottieRef}
//       speed={speed}
//       loop={loop}
//       autoplay={autoplay}
//       data={animations[animation]}
//       // rendererSettings={rendererSettings}
//       renderConfig={{
//         autoResize: true,
//       }}
//     />
//   )
// }

// export default LottiePlayer